@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
/*@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');*/

body {
  margin: 0;
  /*font-family: -apple-system, 'Roboto', sans-serif;*/
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #29323C;
  position: relative;
  min-height: 100vh;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


